import React from 'react'
import { bs } from '../shevy'
import Spacer from './Spacer'

export default function Checkbox({
  checked,
  description,
  label,
  name,
  onChange,
}) {
  return (
    <label
      css={{
        fontFamily: 'var(--fonts-catamaran)',
      }}
      htmlFor={name}
    >
      <span css={{ fontWeight: 'bold', marginBottom: bs(0.25) }}>{label}</span>
      <div css={{ display: 'flex' }}>
        <Spacer right={0.25}>
          <input
            checked={checked}
            id={name}
            name={name}
            onChange={onChange}
            type="checkbox"
          />
        </Spacer>
        {description && <div>{description}</div>}
      </div>
    </label>
  )
}
