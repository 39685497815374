import React from 'react'
import { Link, navigate } from 'gatsby'
import Button from '../components/Button'
import Spacer from '../components/Spacer'
import { Input } from '../components/Inputs'
import PasswordInput from '../components/PasswordInput'
import { bs } from '../shevy'
import MainInner from '../components/MainInner'
import addNewsletterSubscriber from '../utils/addNewsletterSubscriber'
import useSearchParams from '../hooks/useSearchParams'
import Checkbox from '../components/Checkbox'
import { useAuthContext } from '../contexts/AuthContext'
import Seo from '../components/Seo'

export default function Signup() {
  const [authState, { signUp }] = useAuthContext()
  const [state, setState] = React.useState('IDLE')
  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')
  const [newsletter, setNewsletter] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState(null)
  const params = useSearchParams()

  const handleNameChange = React.useCallback(e => {
    setState('IDLE')
    setErrorMessage(null)
    setName(e.target.value)
  }, [])

  const handleEmailChange = React.useCallback(e => {
    setState('IDLE')
    setErrorMessage(null)
    setEmail(e.target.value)
  }, [])

  const handlePasswordChange = React.useCallback(e => {
    setState('IDLE')
    setErrorMessage(null)
    setPassword(e.target.value)
  }, [])

  const handleConfirmPasswordChange = React.useCallback(e => {
    setState('IDLE')
    setErrorMessage(null)
    setConfirmPassword(e.target.value)
  }, [])

  const handleNewsletterChange = React.useCallback(e => {
    setNewsletter(e.target.checked)
  }, [])

  const createUser = React.useCallback(() => {
    return signUp(email, password)
  }, [email, password, signUp])

  const addToNewsletter = React.useCallback(
    () => addNewsletterSubscriber({ email, name }),
    [email, name]
  )

  const handleSubmit = React.useCallback(
    async e => {
      e.preventDefault()

      setState('PENDING')

      if (!password || !confirmPassword || password !== confirmPassword) {
        setErrorMessage('Passwords do not match')
        setState('FAILURE')
        return
      }

      try {
        const promises = [createUser()]

        if (newsletter) {
          promises.push(addToNewsletter())
        }

        const results = await Promise.allSettled(promises)

        const rejections = results.filter(
          result => result.status === 'rejected'
        )

        if (!rejections.length) {
          setState('SUCCESS')
          return
        }

        setErrorMessage(
          rejections.map(rejection => rejection.reason).join(', ')
        )
        setState('FAILURE')
      } catch (error) {
        setErrorMessage(error.message)
        setState('FAILURE')
      }
    },
    [password, confirmPassword, createUser, newsletter, addToNewsletter]
  )

  if (authState === 'AUTHENTICATED') {
    navigate('/learn')
  }

  return (
    <>
      <Seo title="Signup" />
      <MainInner>
        <h2>Sign up</h2>
        <HandleParams params={params} />
        <p>Create an account to get started learning today</p>
        <p>
          <strong>
            If you already have an account, please{' '}
            <Link to={`/login?${params.toString()}`}>login</Link>.
          </strong>
        </p>
        <div css={{ backgroundColor: 'var(--colors-offset)', padding: bs() }}>
          {state === 'FAILURE' && (
            <div
              css={{
                backgroundColor: 'var(--colors-error)',
                color: 'hsl(0 0% 100%)',
                padding: bs(0.5),
                marginBottom: bs(),
              }}
            >
              Error: {errorMessage}
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <Spacer bottom={0.5}>
              <Input
                label="Name"
                name="name"
                onChange={handleNameChange}
                required
                value={name}
              />
            </Spacer>
            <Spacer bottom={0.5}>
              <Input
                label="Email"
                name="email"
                onChange={handleEmailChange}
                required
                value={email}
              />
            </Spacer>
            <Spacer bottom={0.5}>
              <PasswordInput
                label="Password"
                name="password"
                onChange={handlePasswordChange}
                required
                value={password}
              />
            </Spacer>
            <Spacer bottom={0.5}>
              <PasswordInput
                label="Confirm Password"
                name="confirmPassword"
                onChange={handleConfirmPasswordChange}
                required
                value={confirmPassword}
              />
            </Spacer>
            <Spacer bottom={1}>
              <Checkbox
                checked={newsletter}
                description="Check this box if you want to subscribe to my newsletter. No hard feelings if you don't."
                label="Newsletter?"
                name="newsletter"
                onChange={handleNewsletterChange}
              />
            </Spacer>

            <Spacer bottom={1}>
              <div
                css={{
                  fontFamily: 'var(--fonts-catamaran)',
                  fontSize: '.85rem',
                }}
              >
                *Required field
              </div>
            </Spacer>

            <Button
              disabled={state === 'PENDING'}
              onClick={handleSubmit}
              type="submit"
            >
              Submit
            </Button>
          </form>
          {state === 'SUCCESS' && (
            <Spacer top={1}>
              <div
                css={{
                  backgroundColor: 'var(--colors-accent)',
                  color: 'hsl(0 0% 100%)',
                  padding: bs(0.5),
                }}
              >
                Success! Thank you for creating an account. Check for an email
                asking you to confirm your account. You'll be able to login once
                you've done that.
              </div>
            </Spacer>
          )}
        </div>
      </MainInner>
    </>
  )
}

function HandleParams({ params }) {
  if (!Array.from(params.keys()).length) return null

  if (Array.from(params.entries()).some(entry => entry.includes('purchase'))) {
    return (
      <Spacer bottom={1}>
        <div
          css={{
            backgroundColor: 'var(--colors-offset)',
            borderLeft: '6px solid var(--colors-accent)',
            fontFamily: 'var(--fonts-catamaran)',
            padding: bs(),
          }}
        >
          <strong>
            It looks like you need to create an account or sign in before
            purchasing a course.
          </strong>
        </div>
      </Spacer>
    )
  }

  return null
}
