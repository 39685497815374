const API_KEY = process.env.GATSBY_BUTTONDOWN_EMAIL_API_KEY

export default async function addNewsletterSubscriber({ email, name }) {
  try {
    const response = await fetch(
      'https://api.buttondown.email/v1/subscribers',
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${API_KEY}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          metadata: {
            name,
          },
          tags: ['From courses.kyleshevlin.com'],
        }),
      }
    )

    if (!response.ok) {
      return Promise.reject(
        'Something went wrong while attempting to subscribe you to the newsletter'
      )
    }

    const json = await response.json()

    return Promise.resolve(json)
  } catch (error) {
    console.error(error)
    throw new Error(
      'Something went wrong while attempting to subscribe you to the newsletter'
    )
  }
}
